<template>
  <div class="company-services">
    <div class="company-header" @click="toggleExpanded">
      <div class="py-3 d-flex">
        <b-icon-chevron-down v-if="value" class="my-auto" scale="1.2" />
        <b-icon-chevron-up v-else class="my-auto" scale="1.2" />
        <span class="ml-2 my-auto">{{ company.name }}</span>
      </div>
      <spinner v-if="loading"
               class="ml-auto mr-0 small-loading-spinner"
      />
    </div>
    <div v-show="expanded" class="services-body">
      <div v-if="servicesLoaded && services.length && value" class="w-100">
        <b-table
          :items="services"
          :fields="columns"
          :stacked="$mq === 'sm'"
        >
          <template #head(service)="scope">
            <div class="d-flex">
              <b-checkbox
                v-if="$mq !== 'sm' && !shared"
                class="mr-1"
                :checked="allSelected"
                @change="selectAll"
              />
              {{ scope.label }}
            </div>
          </template>
          <template #cell(service)="data">
            <div class="d-flex">
              <b-checkbox
                v-if="!shared"
                class="mr-1"
                :disabled="!isSelectable(data.item)"
                :checked="isSelected(data.item)"
                @change="updateSelected(data.item)"
              />
              <div>{{ data.item.product.name }}</div>
              <div v-if="phoneServiceNumber(data.item)">
                {{ phoneServiceNumber(data.item) }}
              </div>
              <div v-if="isVIPBundle(data.item) || isTrialActive(data.item)" class="ml-2">
                <b-badge v-if="isVIPBundle(data.item)" variant="warning">
                  VIP
                </b-badge>
                <b-badge v-if="isTrialActive(data.item)"
                         class="trial-badge"
                         :class="isVIPBundle(data.item) && 'ml-1'"
                >
                  Trial
                </b-badge>
              </div>
            </div>
          </template>
          <template #cell(jurisdiction)="data">
            {{ jurisdiction(data.item) }}
          </template>
          <template #cell(address)="data">
            <div v-if="data.item.status === 'cancel-requested'">
              <b-badge variant="danger">
                Cancellation Requested
              </b-badge>
            </div>
            <div v-if="data.item.product.name === 'Vehicle Registration Renewal'">
              {{ data.item.product.description }}
            </div>
            <div v-else-if="data.item.product.registered_agent">
              <div v-for="(line, index) in registeredAgentAddress(data.item)" :key="index">
                {{ line }}
              </div>
              <div v-if="showRegisteredAgentModalLink(data.item)" class="ra-details-link">
                <a @click="showRegisteredAgentDetails(data.item)">
                  More Details
                </a>
              </div>
            </div>
            <div v-else-if="data.item.type === 'mail-forwarding'">
              <div v-if="data.item.status === 'active'">
                <div v-for="(line, index) in mailForwardingAddress(data.item)" :key="index">
                  {{ line }}
                </div>
              </div>
              <div v-else-if="data.item.status === 'new'">
                Please allow 1-2 business days to receive your assigned suite
              </div>
            </div>
            <div v-else-if="data.item.type === 'office-rental'">
              <div>
                Office Number: {{ data.item.data.office_number }}
              </div>
            </div>
            <div v-else>--</div>
            <div v-if="uploadLimit(data.item)">
              <em>Document Limit: {{ uploadLimit(data.item) }}</em>
            </div>
          </template>
          <template #cell(rate)="data">
            <div v-if="!isDomainService(data.item)">
              <div
                v-if="isComplianceService(data.item)"
                :id="`compliance-service-billing-info-${data.item.id}`"
              >
                <span>{{ complianceServicePriceDisplay(data.item) }}</span>
              </div>
              <div v-else>{{ data.item.renewal_price | currency }} </div>
              <b-tooltip
                :target="`compliance-service-billing-info-${data.item.id}`"
                triggers="hover"
              >
                This is an estimate based on current state fees. Fees will be communicated prior to payment
              </b-tooltip>
            </div>
            <div v-else>--</div>
            <div v-if="isRenewable(data.item)">
              {{ durationText(data.item, isComplianceService(data.item)) }}
            </div>
          </template>
          <template #cell(status)="data">
            {{ capitalize(data.item.status) }}
          </template>
          <template #cell(autopay)="data">
            <div v-if="shared">Owner managed</div>
            <div v-else-if="isRenewable(data.item)">
              <b-button
                :id="`autopay-button-${data.item.id}`"
                class="autopay-button"
                variant="light"
                aria-label="Setup Autopay Button"
                :disabled="!autopayPayableSelectable(data.item.status)"
                @click="manageAutopay([data.item.id])"
              >
                <div v-if="getPayableInfo(data.item)" class="d-flex">
                  <b-img height="25px" :src="getPayableInfo(data.item).image" />
                  <span class="ml-1 text-nowrap"> {{ getPayableInfo(data.item).number }}</span>
                </div>
                <div v-else>Setup Autopay</div>
              </b-button>
            </div>
            <b-tooltip
              :target="`autopay-button-${data.item.id}`"
              triggers="hover"
            >
              Manage autopay for service
            </b-tooltip>
          </template>
          <template #cell(invoicing)="data">
            <div v-if="canOptIntoPaperBilling(data.item)" class="pr-4">
              <b-select
                :id="`mail-opt-in-${data.item.id}`"
                v-model="data.item.data.paper_billing"
                class="invoicing-selector"
                :options="[{ text:'Email', value: false }, { text: 'Paper', value: true }]"
                :disabled="data.item.status !== 'active' || shared"
                @input="handlePaperBillingOptIn(data.item)"
              />
              <b-tooltip
                :target="`mail-opt-in-${data.item.id}`"
                triggers="hover"
                custom-class="disabled-tooltip"
              >
                <div>
                  Invoices are delivered by email if you are signed up with autopay.
                  Otherwise invoices are mailed.
                </div>
                <div>
                  If you have autopay enabled, you can still opt in to paper invoices
                </div>
              </b-tooltip>
            </div>
            <div v-else class="pt-2 pr-4 pl-2">
              Paper
            </div>
          </template>
        </b-table>
      </div>

      <spinner v-if="value && !servicesLoaded" class="mx-auto large-loading-spinner" />
      <div v-else-if="value && !services.length" class="w-100 no-services">
        <p>No services found</p>
      </div>
    </div>
    <registered-agent-details-modal
      :ref="`registered-agent-details-modal-component`"
      :interaction="interaction"
      :ra-service="modalService"
    />
  </div>
</template>

<script>

import { mapActions, mapGetters, mapMutations } from 'vuex'
import { companyServiceMixin } from '@/mixins/companyServiceMixin'
import { makeToastMixin } from '@/mixins/makeToastMixin'
import { createOrFindClientInteractionLog } from '@/common/modules/clientInteractionLog'
import { SET_SELECTED_SERVICES } from '@/store/mutations'
import _ from 'lodash'
import http, { paramsSerializer } from '@/http'

const statusTypes = [
  'active',
  'awaiting-client-input',
  'verification-needed',
  'waiting on information',
  'waiting on payment',
  'cancel-requested',
  'new',
  'trial-active',
  'awaiting-trial-activation',
]

export default {
  name: 'CompanyServices',
  components: {
    RegisteredAgentDetailsModal: () => import('@/components/RegisteredAgent/RegisteredAgentDetailsModal'),
    Spinner:                     () => import('@images/ui/spinner.svg'),
  },
  mixins: [makeToastMixin, companyServiceMixin],
  props: {
    company: {
      type: Object,
      required: true,
    },
    wholesalerFilters: {
      type: Object,
      default: () => ({}),
    },
    searchFilter: {
      type: String,
      default: null,
    },
    shared: {
      type: Boolean,
      default: false,
    },
    showCancelled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      columns: ['service', 'jurisdiction', 'address', 'rate', 'status', 'autopay', 'invoicing'],
      debounceSearch: _.debounce(this.applySearch, 1000),
      interaction: null,
      loading: false,
      modalService: null,
      services: [],
      servicesLoaded: false,
      includeCancelled: this.showCancelled,
      expanded: true,
    }
  },
  computed: {
    ...mapGetters('services', [
      'companyServices',
      'selectedServices',
      'serviceBillingDetails',
    ]),
    allSelected() {
      return this.services.every(svc => this.selectedServices.some(ss => ss.id === svc.id))
    },
    requestParams() {
      const params =  {
        status: statusTypes,
        company_id: this.company.id,
        include_company: true,
        include_jurisdiction: true,
        include_virtual_phone: true,
        include_mail_forwarding: true,
        vip_format: true,
      }
      if(this.searchFilter) {
        params.search_name = 'search_company_services_page'
        params.query = this.searchFilter
      }
      else if (this.wholesalerFilters) {
        const filterMappings = {
          jurisdiction_id: 'jurisdiction_id',
          service_type: 'service_type',
          start: 'start',
          stop: 'stop',
        }
        const query = {}

        Object.entries(filterMappings).forEach(([filterKey, queryKey]) => {
          if (this.wholesalerFilters[filterKey]) {
            params.search_name = 'search_filtered_wholesaler'
            query[queryKey] = this.wholesalerFilters[filterKey]
          }
        })

        if (this.wholesalerFilters["status_type"]) {
          params.status = this.wholesalerFilters["status_type"]
        }
        if (this.includeCancelled === true) {
          params.status = [...statusTypes, 'cancelled']
        }

        if (Object.keys(query).length > 0) {
          params.query = query
        }
      }
      return params
    },
  },
  watch: {
    value(val) {
      if (!this.servicesLoaded && val) {
        this.loadServices()
      }
      if (!val) {
        this.setSelected([...this.selectedServices.filter(ss => this.services.every(svc => svc.id !== ss.id))])
      }
    },
    searchFilter(val) {
      if(val) {
        this.debounceSearch()
      }else {
        this.loading = false
        this.servicesLoaded = false
        this.$emit('input', false)
      }
    },
  },
  mounted() {
    if (this.value) {
      this.loadServices()
    }
  },
  methods: {
    ...mapActions('services', [
      'getServices',
    ]),
    ...mapMutations('services', { setSelected: SET_SELECTED_SERVICES }),
    applySearch() {
      this.$emit('input', false)
      if(!this.searchFilter) return

      this.loadServices().then(() => {
        if(this.services.length > 0 && this.searchFilter) {
          this.$emit('input', true)
        }
        else {
          this.servicesLoaded = false
          this.$emit('input', false)
        }
      })
    },
    capitalize(word) {
      return word.charAt(0).toUpperCase() + word.slice(1)
    },
    isSelected(service) {
      return this.selectedServices.some(svc => svc.id === service.id)
    },
    isComplianceService(service) {
      return service?.type === 'compliance' && !!this.serviceBillingDetails[service?.object_id]
    },
    complianceServicePriceDisplay(service) {
      const cost = this.serviceBillingDetails[service?.object_id]?.cost
      const stateFees =  this.serviceBillingDetails[service?.object_id]?.stateFees
      const total = cost + stateFees

      return (total && total !== 0) ?
        `${this.formatCurrency(total)}` : ''
    },
    async loadServices() {
      this.loading = true
      this.servicesLoaded = false

      try {
        let result
        if(this.shared) {
          const { data } = await http.get(`client/services/shared`, { params: this.requestParams, paramsSerializer })
          result = data
        } else {
          result = await this.getServices(this.requestParams)
        }
        if (result?.success) {
          this.services = (this.shared ? result.result : this.companyServices(this.company.id))
            .sort((a, b) => a.product.name.localeCompare(b.product.name))
          this.servicesLoaded = true
        } else {
          this.errorToast('Error loading services')
        }
      } catch {
        this.errorToast('Error', 'Error loading company services')
      }
      this.loading = false
    },
    selectAll() {
      const filtered = [...this.selectedServices.filter(ss => this.services.every(svc => svc.id !== ss.id))]
      this.setSelected(this.allSelected
        ? [...filtered]
        : [...filtered, ...this.services],
      )
    },
    showRegisteredAgentDetails(service) {
      if (this.isNotAdminUser) {
        createOrFindClientInteractionLog({
          category: 'registered-agents',
          subCategory: 'registered-agent-details-modal',
          interaction: {
            action: 'visit',
            name: 'open-modal',
          },
          incompleteLogFromToday: true,
        }).then((interaction) => {
          this.interaction = interaction
        })
      }
      this.modalService = service
      const ref = this.$refs[`registered-agent-details-modal-component`]
      ref.$refs['registered-agent-details-modal'].show()
    },
    toggleExpanded() {
      if(!this.loading) {
        this.expanded = !this.expanded
        this.$emit('input', !this.value)
      }
    },
    updateSelected(service) {
      if (this.isSelected(service)) {
        this.setSelected([...this.selectedServices.filter(svc => svc.id !== service.id)])
      } else {
        this.setSelected([...this.selectedServices, service])
      }
    },
    uploadLimit(item) {
      return item.product.upload_limit
    },
  },
}
</script>


<style scoped lang="scss">

.company-services {
  width: 100%;
  min-width: fit-content;
  border: 1px solid #DCDDDD;
  border-radius: 5px;

  .company-header {
    width: 100%;
    display: flex;
    padding: 0 1rem 0 1rem;

    :hover {
      cursor: pointer;
    }
  }

  .small-loading-spinner {
    width: 3rem;
    height: 3rem;
  }

  .large-loading-spinner {
    width: 5rem;
    height: 5rem;
  }

  .services-body {
    display: flex;
    border-top: 1px solid #dcdddd;


    ::v-deep .table.b-table > thead > tr > th {
      border-bottom: none;
      background-color: #F8F8F8;
      &:last-child{
        padding-right: 1rem;
      }
    }

   ::v-deep .b-table-stacked {
     margin-bottom: 0;

     tr:not(:last-child) {
       border-bottom: 2px solid black;
     }

     td {
       padding: 0;
       display: flex;
       border-top: none;

       > div {
         width: 65% !important;
         padding: .75rem .25rem .75rem .25rem !important;
       }

       &::before {
         background-color: #F8F8F8;
         width: 35% !important;
         min-width: 120px;
         text-align: left !important;
         padding: .75rem !important;
       }

       &:nth-last-child(-n+2)::before {
         align-content: center;
       }
     }
    }

    .autopay-button {
      padding: 0 .75rem 0 .75rem;
      border: 1px solid #DCDDDD;
      border-radius: 4px;
      min-height: 45px;
      min-width: 145px;
      background: white;
    }

    .trial-badge {
      background: #30B2AE;
    }

    .ra-details-link {
      color: #30B2AE !important;
      text-decoration: underline !important;

      :hover {
        cursor: pointer !important;
      }
    }

    .invoicing-selector {
      min-height: 45px !important;
      width: 100px;
    }

    .service-row {
      display: flex;
      flex-direction: row;
    }
  }

  .no-services {
    padding: 1rem 0 0 1rem;
  }
}

@media only screen and (max-width: 767px) {
  .disabled-tooltip {
    display: none;
    * {
      display: none;
    }
  }
}

</style>
