var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "company-services" },
    [
      _c(
        "div",
        { staticClass: "company-header", on: { click: _vm.toggleExpanded } },
        [
          _c(
            "div",
            { staticClass: "py-3 d-flex" },
            [
              _vm.value
                ? _c("b-icon-chevron-down", {
                    staticClass: "my-auto",
                    attrs: { scale: "1.2" },
                  })
                : _c("b-icon-chevron-up", {
                    staticClass: "my-auto",
                    attrs: { scale: "1.2" },
                  }),
              _c("span", { staticClass: "ml-2 my-auto" }, [
                _vm._v(_vm._s(_vm.company.name)),
              ]),
            ],
            1
          ),
          _vm.loading
            ? _c("spinner", {
                staticClass: "ml-auto mr-0 small-loading-spinner",
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.expanded,
              expression: "expanded",
            },
          ],
          staticClass: "services-body",
        },
        [
          _vm.servicesLoaded && _vm.services.length && _vm.value
            ? _c(
                "div",
                { staticClass: "w-100" },
                [
                  _c("b-table", {
                    attrs: {
                      items: _vm.services,
                      fields: _vm.columns,
                      stacked: _vm.$mq === "sm",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "head(service)",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                { staticClass: "d-flex" },
                                [
                                  _vm.$mq !== "sm" && !_vm.shared
                                    ? _c("b-checkbox", {
                                        staticClass: "mr-1",
                                        attrs: { checked: _vm.allSelected },
                                        on: { change: _vm.selectAll },
                                      })
                                    : _vm._e(),
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(scope.label) +
                                      "\n          "
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "cell(service)",
                          fn: function (data) {
                            return [
                              _c(
                                "div",
                                { staticClass: "d-flex" },
                                [
                                  !_vm.shared
                                    ? _c("b-checkbox", {
                                        staticClass: "mr-1",
                                        attrs: {
                                          disabled: !_vm.isSelectable(
                                            data.item
                                          ),
                                          checked: _vm.isSelected(data.item),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.updateSelected(data.item)
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  _c("div", [
                                    _vm._v(_vm._s(data.item.product.name)),
                                  ]),
                                  _vm.phoneServiceNumber(data.item)
                                    ? _c("div", [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.phoneServiceNumber(data.item)
                                            ) +
                                            "\n            "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.isVIPBundle(data.item) ||
                                  _vm.isTrialActive(data.item)
                                    ? _c(
                                        "div",
                                        { staticClass: "ml-2" },
                                        [
                                          _vm.isVIPBundle(data.item)
                                            ? _c(
                                                "b-badge",
                                                {
                                                  attrs: { variant: "warning" },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                VIP\n              "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.isTrialActive(data.item)
                                            ? _c(
                                                "b-badge",
                                                {
                                                  staticClass: "trial-badge",
                                                  class:
                                                    _vm.isVIPBundle(
                                                      data.item
                                                    ) && "ml-1",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                Trial\n              "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "cell(jurisdiction)",
                          fn: function (data) {
                            return [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.jurisdiction(data.item)) +
                                  "\n        "
                              ),
                            ]
                          },
                        },
                        {
                          key: "cell(address)",
                          fn: function (data) {
                            return [
                              data.item.status === "cancel-requested"
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "b-badge",
                                        { attrs: { variant: "danger" } },
                                        [
                                          _vm._v(
                                            "\n              Cancellation Requested\n            "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              data.item.product.name ===
                              "Vehicle Registration Renewal"
                                ? _c("div", [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(data.item.product.description) +
                                        "\n          "
                                    ),
                                  ])
                                : data.item.product.registered_agent
                                ? _c(
                                    "div",
                                    [
                                      _vm._l(
                                        _vm.registeredAgentAddress(data.item),
                                        function (line, index) {
                                          return _c("div", { key: index }, [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(line) +
                                                "\n            "
                                            ),
                                          ])
                                        }
                                      ),
                                      _vm.showRegisteredAgentModalLink(
                                        data.item
                                      )
                                        ? _c(
                                            "div",
                                            { staticClass: "ra-details-link" },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.showRegisteredAgentDetails(
                                                        data.item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                More Details\n              "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                : data.item.type === "mail-forwarding"
                                ? _c("div", [
                                    data.item.status === "active"
                                      ? _c(
                                          "div",
                                          _vm._l(
                                            _vm.mailForwardingAddress(
                                              data.item
                                            ),
                                            function (line, index) {
                                              return _c("div", { key: index }, [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(line) +
                                                    "\n              "
                                                ),
                                              ])
                                            }
                                          ),
                                          0
                                        )
                                      : data.item.status === "new"
                                      ? _c("div", [
                                          _vm._v(
                                            "\n              Please allow 1-2 business days to receive your assigned suite\n            "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ])
                                : data.item.type === "office-rental"
                                ? _c("div", [
                                    _c("div", [
                                      _vm._v(
                                        "\n              Office Number: " +
                                          _vm._s(data.item.data.office_number) +
                                          "\n            "
                                      ),
                                    ]),
                                  ])
                                : _c("div", [_vm._v("--")]),
                              _vm.uploadLimit(data.item)
                                ? _c("div", [
                                    _c("em", [
                                      _vm._v(
                                        "Document Limit: " +
                                          _vm._s(_vm.uploadLimit(data.item))
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                        {
                          key: "cell(rate)",
                          fn: function (data) {
                            return [
                              !_vm.isDomainService(data.item)
                                ? _c(
                                    "div",
                                    [
                                      _vm.isComplianceService(data.item)
                                        ? _c(
                                            "div",
                                            {
                                              attrs: {
                                                id: `compliance-service-billing-info-${data.item.id}`,
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.complianceServicePriceDisplay(
                                                      data.item
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("currency")(
                                                  data.item.renewal_price
                                                )
                                              ) + " "
                                            ),
                                          ]),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: `compliance-service-billing-info-${data.item.id}`,
                                            triggers: "hover",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n              This is an estimate based on current state fees. Fees will be communicated prior to payment\n            "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _c("div", [_vm._v("--")]),
                              _vm.isRenewable(data.item)
                                ? _c("div", [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm.durationText(
                                            data.item,
                                            _vm.isComplianceService(data.item)
                                          )
                                        ) +
                                        "\n          "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                        {
                          key: "cell(status)",
                          fn: function (data) {
                            return [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.capitalize(data.item.status)) +
                                  "\n        "
                              ),
                            ]
                          },
                        },
                        {
                          key: "cell(autopay)",
                          fn: function (data) {
                            return [
                              _vm.shared
                                ? _c("div", [_vm._v("Owner managed")])
                                : _vm.isRenewable(data.item)
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "autopay-button",
                                          attrs: {
                                            id: `autopay-button-${data.item.id}`,
                                            variant: "light",
                                            "aria-label":
                                              "Setup Autopay Button",
                                            disabled:
                                              !_vm.autopayPayableSelectable(
                                                data.item.status
                                              ),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.manageAutopay([
                                                data.item.id,
                                              ])
                                            },
                                          },
                                        },
                                        [
                                          _vm.getPayableInfo(data.item)
                                            ? _c(
                                                "div",
                                                { staticClass: "d-flex" },
                                                [
                                                  _c("b-img", {
                                                    attrs: {
                                                      height: "25px",
                                                      src: _vm.getPayableInfo(
                                                        data.item
                                                      ).image,
                                                    },
                                                  }),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "ml-1 text-nowrap",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.getPayableInfo(
                                                              data.item
                                                            ).number
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _c("div", [
                                                _vm._v("Setup Autopay"),
                                              ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "b-tooltip",
                                {
                                  attrs: {
                                    target: `autopay-button-${data.item.id}`,
                                    triggers: "hover",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            Manage autopay for service\n          "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                        {
                          key: "cell(invoicing)",
                          fn: function (data) {
                            return [
                              _vm.canOptIntoPaperBilling(data.item)
                                ? _c(
                                    "div",
                                    { staticClass: "pr-4" },
                                    [
                                      _c("b-select", {
                                        staticClass: "invoicing-selector",
                                        attrs: {
                                          id: `mail-opt-in-${data.item.id}`,
                                          options: [
                                            { text: "Email", value: false },
                                            { text: "Paper", value: true },
                                          ],
                                          disabled:
                                            data.item.status !== "active" ||
                                            _vm.shared,
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.handlePaperBillingOptIn(
                                              data.item
                                            )
                                          },
                                        },
                                        model: {
                                          value: data.item.data.paper_billing,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              data.item.data,
                                              "paper_billing",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "data.item.data.paper_billing",
                                        },
                                      }),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: `mail-opt-in-${data.item.id}`,
                                            triggers: "hover",
                                            "custom-class": "disabled-tooltip",
                                          },
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              "\n                Invoices are delivered by email if you are signed up with autopay.\n                Otherwise invoices are mailed.\n              "
                                            ),
                                          ]),
                                          _c("div", [
                                            _vm._v(
                                              "\n                If you have autopay enabled, you can still opt in to paper invoices\n              "
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _c("div", { staticClass: "pt-2 pr-4 pl-2" }, [
                                    _vm._v("\n            Paper\n          "),
                                  ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3605465281
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.value && !_vm.servicesLoaded
            ? _c("spinner", { staticClass: "mx-auto large-loading-spinner" })
            : _vm.value && !_vm.services.length
            ? _c("div", { staticClass: "w-100 no-services" }, [
                _c("p", [_vm._v("No services found")]),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c("registered-agent-details-modal", {
        ref: `registered-agent-details-modal-component`,
        attrs: { interaction: _vm.interaction, "ra-service": _vm.modalService },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }